import React from 'react';
import { useTranslation } from 'react-i18next';

function ContactInfoArea() {
    const { t } = useTranslation();

    return (
        <div className="contact-info-area">
            <div className="container">
                <div className="row gx-xl-5">
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="icon-card style-four">
                            <div className="icon">
                                <i className="fa-solid fa-location-dot"></i>
                            </div>
                            <div className="content">
                                <h2 className="title">{t('contact_info.address')}</h2>
                                <p className="desc">{t('companyAddress')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="icon-card style-four">
                            <div className="icon">
                                <i className="fa-solid fa-phone"></i>
                            </div>
                            <div className="content">
                                <h2 className="title">{t('contact_info.phone')}</h2>
                                <p className="desc">{t('contactInfoPhone')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="icon-card style-four">
                            <div className="icon">
                                <i className="fa-solid fa-envelope"></i>
                            </div>
                            <div className="content">
                                <h2 className="title">{t('contact_info.email')}</h2>
                                <p className="desc">{t('companyEmail')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactInfoArea;
