import React from 'react';
import { useTranslation } from 'react-i18next';
import FooterLogoWhite from "../assets/images/logo/footer-logo-white.png";
import Instagram1 from "../assets/images/social-media/1.jpg";
import Instagram2 from "../assets/images/social-media/2.jpg";
import Instagram3 from "../assets/images/social-media/3.jpg";
import Instagram4 from "../assets/images/social-media/4.jpg";
import Instagram5 from "../assets/images/social-media/5.jpg";
import Instagram6 from "../assets/images/social-media/6.jpg";
import FooterMapShape from "../assets/images/section-bg/footer-map-shape.png";
import MasterCard from '../assets/images/icon/mastercard.png'
import Mada from '../assets/images/icon/mada.png'
import Cash from '../assets/images/icon/cash.png'
import VISA from '../assets/images/icon/visa.png'
import { Link } from "react-router-dom";
import SocialMediaLinks from "./SocialMediaLinks";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="footer bg-light-black">
            <div className="footer-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                            <div className="footer-widget">
                                <div className="footer-widget-info">
                                    <div className="footer-logo">
                                        <Link to={'/'}>
                                            <img loading="lazy" src={FooterLogoWhite} width="100" height="35px"
                                                 alt="Footer Logo"/>
                                        </Link>
                                    </div>
                                    <div className="footer-widget-contact">
                                        <div className="footer-contact">
                                            <ul>
                                                <li>
                                                    <div className="contact-icon">
                                                        <i className="fa-solid fa-location-dot"></i>
                                                    </div>
                                                    <div className="contact-text">
                                                        <span>{t('companyAddress')}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="contact-icon">
                                                        <i className="fa-light fa-envelope"></i>
                                                    </div>
                                                    <div className="contact-text">
                                                        <a href={`mailto:${t('footer.email')}`}>
                                                            <span>{t('companyEmail')}</span>
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="contact-icon">
                                                        <i className="fa-solid fa-phone"></i>
                                                    </div>
                                                    <div className="contact-text">
                                                        <span>{t('contactInfoPhone')}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="social-profile">
                                        <SocialMediaLinks />
                                    </div>
                                    <div className="social-profile">
                                        <img loading="lazy" src={MasterCard} alt="instagram photo" width={80}/>
                                        <img loading="lazy" src={VISA} alt="instagram photo" width={80}/>
                                        <img loading="lazy" src={Mada} alt="instagram photo" width={80}/>
                                        <img loading="lazy" src={Cash} alt="instagram photo" width={80}/>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-3 col-xl-4 col-lg-3 col-md-6">
                            <div className="row">
                                <div className="col-12">
                                    <div className="footer-widget widget_nav_menu">
                                        <h2 className="footer-widget-title">{t('footer.links')}</h2>
                                        <ul className="menu">
                                            <li><Link to={'/'} >{t('home')}</Link></li>
                                            <li><Link to={'/about'} >{t('about')}</Link></li>
                                            <li><Link to={'/services'} >{t('services')}</Link></li>
                                            <li><Link to={'/track-trace'} >{t('track-trace')}</Link></li>
                                            {/*<li><Link to={'/branches'} >{t('branches')}</Link></li>*/}
                                            <li><Link to={'/contact'} >{t('contact')}</Link></li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-6">
                            <div className="footer-widget">
                                <h2 className="footer-widget-title">{t('footer.social_media')}</h2>
                                <div className="widget-instagram-feed">
                                    <div className="single-instagram-feed">
                                        <img loading="lazy" src={Instagram1} alt="instagram photo"/>
                                    </div>
                                    <div className="single-instagram-feed">
                                        <img loading="lazy" src={Instagram2} alt="instagram photo"/>
                                    </div>
                                    <div className="single-instagram-feed">
                                        <img loading="lazy" src={Instagram3} alt="instagram photo"/>
                                    </div>
                                    <div className="single-instagram-feed">
                                        <img loading="lazy" src={Instagram4} alt="instagram photo"/>
                                    </div>
                                    <div className="single-instagram-feed">
                                        <img loading="lazy" src={Instagram5} alt="instagram photo"/>
                                    </div>
                                    <div className="single-instagram-feed">
                                        <img loading="lazy" src={Instagram6} alt="instagram photo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                            <div className="footer-widget">
                                <h2 className="footer-widget-title">{t('footer.quick_contact')}</h2>
                                <div className="widget_latest_post">
                                    <ul>
                                        <li>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title"><a href="#">
                                                    <span className="latest-post-meta">920019000</span>
                                                    {t('footer.contact_inside_saudi')}
                                                </a>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title"><a href="#">
                                                    <span className="latest-post-meta">8001189000</span>
                                                    {t('footer.complaints')}
                                                </a>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="latest-post-desc">
                                                <h3 className="latest-post-title"><a href="#">
                                                    <span className="latest-post-meta">00966114020036</span>
                                                    {t('footer.contact_outside_saudi')}
                                                </a>
                                                </h3>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom-area">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-4 text-center">
                            <div className="copyright-text">
                                <p>Designed By <a href="#">Invent Solutions</a></p>
                            </div>
                        </div>
                        <div className="col-lg-4 text-center">
                            <div className="copyright-text">
                                <p> Copywrite &copy;<a href="#">SMB Express</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bg">
                <img loading="lazy" src={FooterMapShape} alt="footer image"/>
            </div>
        </footer>
    );
}

export default Footer;
