import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/style-rtl.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import TrackTrace from "./pages/TrackTrace";
import Services from "./pages/Services";
import "./assets/css/fontawesome.min.css";
import NotFoundPage from "./pages/NotFoundPage";
import ScrollToTopButton from "./component/ScrollToTopButton";
import "../src/assets/css/Header.css";
import axios from "axios";
import TrackingContainer from "./component/TrackingContainer";
import CameraScan from "./component/CameraScan";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

function App() {
  const [trackingData, setTrackingData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const handleRequest = async (trackingCode) => {
    setLoading(true);
    setError(null);

    try {
        let url = `https://track.inventdemo.com/${trackingCode}`;
        window.open(url, '_blank').focus();
      /*
            const response = await axios.get(`https://cors-anywhere.herokuapp.com/https://api.smb.express/v1/tracking?tracking_codes=${trackingCode}`);
            if (response.data && response.data.items && response.data.items.length > 0) {
                toast.success('Tracking data retrieved successfully!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });

                setTrackingData(response.data);
            } else {
                toast.error('Error: Unable to fetch tracking data!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setError("No tracking data found.");
            }*/
    } catch (error) {
      toast.error("Error: Unable to fetch tracking data!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      console.log("test qr");
      setError(error.message);
    }

    setLoading(false);
  };
  const handleRemoveData = () => {
    setTrackingData(null);
  };

  return (
    <>
      <CameraScan handleRequest={handleRequest} />
      <ToastContainer />
      <React.StrictMode>
        {/*<Preloader />*/}
        <ScrollToTopButton />
        {/* Same as */}
        <TrackingContainer
          trackingData={trackingData}
          handleRemoveData={handleRemoveData}
        />
        {/*{loading && <p className="text-primary m-2">...Loading</p>}*/}
        {/*{error && <p className="text-danger m-2">خطأ: أعد كتابه الكود بطريقه صحيحه</p>}*/}
        <Router>
          <Routes>
            <Route path="/" element={<Home handleRequest={handleRequest} />} />
            <Route path="/about" element={<About />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/services" element={<Services />} />
            <Route
              path="/track-trace"
              element={<TrackTrace handleRequest={handleRequest} />}
            />
            <Route path="/contact" element={<Contact />} />
            {/*<Route path="/branches" element={<Branches/>}/>*/}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </React.StrictMode>
    </>
  );
}

export default App;
